import WalletConnectProvider from "@walletconnect/web3-provider";
//import Torus from "@toruslabs/torus-embed"
import WalletLink from "walletlink";
import "antd/dist/antd.css";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import Web3Modal from "web3modal";
import "./App.css";
import { Account, Contract, Faucet, GasGauge, Header, Ramp, ThemeSwitch } from "./components";
import { INFURA_ID, NETWORK, NETWORKS } from "./constants";
import { Transactor } from "./helpers";
import { Grid, Button } from "@material-ui/core"
// import Hints from "./Hints";
import { ExampleUI, Hints, Subgraph } from "./views";
import styled from 'styled-components'

// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import externalContracts from "./contracts/external_contracts";

import { useContractConfig } from "./hooks";
import Portis from "@portis/web3";
import Fortmatic from "fortmatic";
import Authereum from "authereum";



function App(props) { 

  return (<StyledPage>
  <Grid container direction="column" justifyContent="center" alignItems="center" spacing={5}>

  <Grid item>
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
      <Grid item>
        <h1>gm coin</h1>
      </Grid>
      <Grid item>
        5% fee auto add to the liquidity pool to locked forever when selling
      </Grid>
      <Grid item>
        5% fee auto distribute to all holders
      </Grid>
      <Grid item>
        Created a black hole so $gm Coin will deflate itself in supply with every transaction
      </Grid>
      (i.e. copypasta of other memecoins)
    </Grid>
  </Grid>

  <Grid item>

    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Button variant="outlined" onClick={() => window.open("https://twitter.com/gmcoin_eth", "_blank")}>
        Twitter
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0x73b8726618f53F84eEb860FD50AB217fdf30dEa0", "_blank")}>
        Uniswap
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={() => window.open("https://www.dextools.io/app/ether/pair-explorer/0xe9209bd16ab1f66a9185d11bbecf26d23054063a", "_blank")}>
        Dextools
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={() => window.open("https://discord.gg/G2cSbUksJk", "_blank")}>
        Discord
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={() => window.open("https://t.me/gmCoin_eth", "_blank")}>
        Telegram
        </Button>
      </Grid>

    </Grid>
  </Grid>
  <Grid item>
    <GMs />
  </Grid>

  </Grid>

    
  </StyledPage>)
}


const GMs = (props) => {

  let gms = []
  for (let i = 0; i < 3000; i++) {
    let gm = (<Grid item key={i}>
      gm
    </Grid>)
    gms.push(gm)
  }



  return (
    <Grid container spacing={1}>
      {gms}
    </Grid>
  )
}

const StyledPage = styled.div`
  padding-top: 100px;
`

export default App;
